import React, { Fragment, useState, useEffect } from 'react';
import { RichText } from 'prismic-reactjs';
import useWidth from '../../../hooks/useWidth';

import { linkResolver } from '../../../utils/linkResolver';
import logo from '../../../assets/dwlogo.svg';
import logoWhite from '../../../assets/dwlogo_white.svg';

import { 
  Gap, 
  Section, 
  Container, 
  LogoLink, 
  Logo, 
  Menu, 
  MobileMenu, 
  MenuItem, 
  InternalLink, 
  ExternalLink,
  ToggleButton,
  ToggleIcon,
  Hamburger, 
  MenuIcon,
  MenuButton,
  MenuButtons
} from './styles';

const Header = ({ menuLinks, transparent, isDark, toggleTheme }) => {
  // Check if client
  const isClient = typeof window !== 'undefined'
  
  // Hooks
  const width = useWidth();
  const mobile = Boolean(width < 992);

  // State
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [white, setWhite] = useState(false);

  // Menu open
  const menuOpen = open && mobile;

  useEffect(() => {
    if (!isClient) return false;

    let oldValue = 0;
    transparent && setWhite(true);

    const scrollCheck = () => {

      // Get the new Value
      const newValue = window.pageYOffset;
      transparent && setWhite(newValue <= 100);
      newValue === 0 && setHide(false);

      // Start hiding header
      if (newValue > 500) {
        // Decide scroll
        setHide(oldValue - newValue < 0);
        // Update the old value
        oldValue = newValue;
      }
    }

    // Add scroll listener
    window.addEventListener("scroll", scrollCheck);
    return () => window.removeEventListener("scroll", scrollCheck);
  }, [isClient, transparent]);

  // Freeze content below menu
  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : '';
  }, [menuOpen]);

  // Change body color if dark
  useEffect(() => {
    document.body.style.backgroundColor = isDark || transparent ? 'black' : 'white';
  }, [isDark, transparent]);

  // Trigger menu
  const triggerMenu = () => setOpen(!open);

  // Menu
  const menu = menuLinks.map((item, i) => {
    // Render link
    const renderLink = (title, link) => {
      if (link.url) {
        return (
          <ExternalLink href={link.url} white={menuOpen || white}>
            {RichText.asText(title.raw)}
          </ExternalLink>
        );
      } else {
        return (
          <InternalLink to={linkResolver(link)} white={menuOpen || white ? 1 : 0}>
            {RichText.asText(title.raw)}
          </InternalLink>
        );
      }
    };

    return (
      <MenuItem key={`menu-item-${i}`} index={i + 1}>
        {renderLink(item.menu_title, item.menu_link)}
      </MenuItem>
    );
  });

  // Logo
  const headerLogo = (
    <LogoLink to="/">
      <Logo
        src={menuOpen || white || isDark ? logoWhite : logo} 
        alt="Design World"
      />
    </LogoLink>
  );

  // Theme toggle
  const themeToggle = (
    <ToggleButton onClick={toggleTheme} aria-label={`Toggle ${isDark ? 'light' : 'dark'} mode`}>
      <ToggleIcon isDark={isDark} white={white} />  
    </ToggleButton>
  );

  // Menu content
  const renderContent = () => {
    if (mobile) {
      return (
        <Fragment>
          <Container>
            {headerLogo}
            <MenuButtons>
              <MenuButton>
                {themeToggle}
              </MenuButton>
              <Hamburger onClick={triggerMenu} aria-label="Menu">
                <MenuIcon open={menuOpen} white={white}>
                  <span />
                  <span />
                  <span />
                </MenuIcon>
              </Hamburger>
            </MenuButtons>
          </Container>
          {open && <MobileMenu>{menu}</MobileMenu>}
        </Fragment>
      );
    } 
    return (
      <Container>
        {headerLogo}
        <Menu>
          {menu}
          <MenuItem white={white}>
            {themeToggle}
          </MenuItem>
        </Menu>
      </Container>
    );
  };

  return (
    <Fragment>
      {!transparent && <Gap />}
      <Section open={menuOpen} hide={!open && hide} white={white}>
        {renderContent()}
      </Section>
    </Fragment>
  );
};

export default Header;
