import React, { useState, useEffect } from 'react';
import Context from '../context/ThemeContext';

const ContextProvider = ({ children }) => {

  // State
  const [dark, setDark] = useState(false);

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'
    const existingTheme = isBrowser && localStorage.getItem('theme');
    if (existingTheme) {
      setDark(JSON.parse(existingTheme));
    }
  }, []);

  return (
    <Context.Provider
      value={{
        isDark: dark,
        toggleTheme: () => {
          setDark(!dark);
          window.localStorage.setItem('theme', JSON.stringify(!dark))
        }
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default ContextProvider;
