import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/styles';

export const Button = styled.button`
  background: ${({ border, theme }) => border ? 'none' : theme.secondary};
  color: ${({ theme }) => theme.textLight};
  font-family: MuseoSans, sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding: 12px;
  min-width: 160px;
  outline: none;
  border: ${({ border, theme }) => border ? `2px solid ${theme.textLight}` : 'none'};
  border-radius: 30px;
  line-height: 24px;
  cursor: pointer;

  &:active, :focus {
    background: ${({ theme }) => theme.secondaryDark};
    border: ${({ border, theme }) => border ? `2px solid ${theme.secondaryDark}` : 'none'};
  }

  @media (min-width: ${breakpoints.xl}px){
    padding: 16px;
  }
`;