import React from 'react';
import { Global, css } from '@emotion/core';
import * as fonts from '../fonts';

// Theme
export const theme = {
  light: {
    primary: '#141825',
    secondary: '#1A8847',
    secondaryDark: '#156F3A',
    black: '#000000',
    dark: '#111111',
    lighter: '#979797',
    light: '#F0F0F0',
    white: '#FFFFFF',
    textDark: '#000000',
    textLight: '#FFFFFF'
  },
  dark: {
    primary: '#111111',
    secondary: '#1A8847',
    secondaryDark: '#156F3A',
    black: '#000000',
    dark: '#111111',
    lighter: '#979797',
    light: '#323232',
    white: '#222222',
    textDark: '#FFFFFF',
    textLight: '#FFFFFF'
  }
};

// Breakpoints
export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
  xxl: 1920
};

// Dimensions
export const dimensions = {
  contentWidth: '1170px'
};

// Global style
export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      @font-face {
        font-family: 'MuseoSans';
        font-weight: 700;
        font-style: normal;
        src: url(${fonts.museo700Woff2}) format('woff2'), url(${fonts.museo700Woff}) format('woff');
        font-display: auto;
      }
      @font-face {
        font-family: 'MuseoSans';
        font-weight: 500;
        font-style: normal;
        src: url(${fonts.museo500Woff2}) format('woff2'), url(${fonts.museo500Woff}) format('woff');
        font-display: auto;
      }
      @font-face {
        font-family: 'MuseoSans';
        font-weight: 300;
        font-style: normal;
        src: url(${fonts.museo300Woff2}) format('woff2'), url(${fonts.museo300Woff}) format('woff');
        font-display: auto;
      }
      @font-face {
        font-family: 'MuseoSans';
        font-weight: 100;
        font-style: normal;
        src: url(${fonts.museo100Woff2}) format('woff2'), url(${fonts.museo100Woff}) format('woff');
        font-display: auto;
      }

      body {
        margin: 0;
        font-size: 18px;
        font-family: MuseoSans, Helvetica, Arial, Sans-Serif;
        font-weight: 300;

        @media (min-width: ${breakpoints.xl}px){
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }

      h1, h2, h3, h4, h5, h6 {
        font-family: MuseoSans, Helvetica, Arial, Sans-Serif;
        font-weight: 700;
        margin: 0 0 24px 0;
        padding: 0;
        line-height: 1.2;
      }
      h1 {
        font-size: 36px;

        @media (min-width: ${breakpoints.xl}px){
          font-size: 48px;
        }
      }
      h2 {
        font-size: 24px;

        @media (min-width: ${breakpoints.xl}px){
          font-size: 32px;
        }
      }
      h3 {
        font-size: 22px;

        @media (min-width: ${breakpoints.xl}px){
          font-size: 24px;
        }
      }
      h4 {
        font-size: 16px;
      }
      h5 {
        font-size: 14px;
      }
      h6 {
        font-size: 12px;
      }

      p {
        margin: 0 0 24px 0;
        line-height: 1.2

        @media (min-width: ${breakpoints.xl}px){
          margin-bottom: 32px;
        }
      }

      img {
        max-width: 100%;
        margin: 0 0 24px 0;
        padding: 0;
      }

      mark {
        background-color: #ff0;
        color: #000;
      }

      button,
      input,
      optgroup,
      select,
      textarea {
        font: inherit;
        margin: 0;
        box-sizing : border-box;
      }

      a {
        color:inherit;
      }
    `}
  />
);