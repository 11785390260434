import React, { Fragment, useState } from 'react';
import { RichText } from 'prismic-reactjs';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import {
  Logo, 
  Section, 
  Container,
  Column,
  ColumnTitle,
  Promotion,
  Bottom,
  BottomContainerLarge,
  BottomContainerSmall,
  BottomItem,
  BottomGridLeft,
  BottomGridRight,
  SocialIcon,
  SocialLink,
  MailButton,
  MailForm,
  MailInput,
  MailLabel,
  MailDescription,
  MailResult
} from './styles';

import Button from '../../UI/Button';
import InputField from '../../UI/InputField';

import footerLogo from '../../../assets/dwlogo_footer.svg';
import facebook from '../../../assets/social/facebook.svg';
import instagram from '../../../assets/social/instagram.svg';
import linkedin from '../../../assets/social/linkedin.svg';
import pinterest from '../../../assets/social/pinterest.svg';

import { linkResolver } from '../../../utils/linkResolver';

const Footer = ({ footer }) => {

  // State
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);

  // Submit email to mailchimp
  const handleSubmit = async event => {
    event.preventDefault();
    const result = await addToMailchimp(email);
    setMessage(result);
  };

  // Handle change
  const handleChange = event => setEmail(event.target.value);

  // Result
  const renderResultMessage = message => {
    if (message && message.result) {
      return (
        <MailResult>
          {message.result.toUpperCase()}
        </MailResult>
      );
    }
  };

  // Social links
  const socialLinks = [
    {name: 'facebook', icon: facebook, link: 'https://www.facebook.com/designworldlabs'},
    {name: 'instagram', icon: instagram, link: 'https://www.instagram.com/designworldco'},
    {name: 'linkedin', icon: linkedin, link: 'https://www.linkedin.com/company/designworldco'},
    {name: 'pinterest', icon: pinterest, link: 'https://www.pinterest.com/designworldco'},
  ];

  // Promotion
  const promotionColumn = (
    <Fragment>
      <ColumnTitle>
        {RichText.asText(footer.promotion_title.raw)} 
      </ColumnTitle>
      <Promotion>
        {RichText.asText(footer.promotion_text.raw)} 
      </Promotion>
      <Button
        text={RichText.asText(footer.promotion_button_text.raw)} 
        link={linkResolver(footer.promotion_button_link)} 
      />
    </Fragment>
  );

  // Subscribe newsletter
  const newsletterColumn = (
    <MailForm onSubmit={handleSubmit}>
      <MailLabel htmlFor="newsletter">
        {RichText.asText(footer.newsletter_title.raw)} 
      </MailLabel>
      <MailDescription>
        {RichText.asText(footer.newsletter_text.raw)} 
      </MailDescription>
      <MailInput>
        <InputField
          id='newsletter'
          placeholder={footer.newsletter_input_placeholder}
          onChange={handleChange}
          withButton
        />
        <MailButton>
          {footer.newsletter_button_text}
        </MailButton>
      </MailInput>
      {renderResultMessage(message)}
    </MailForm>
  );

  // Social
  const socialMenu = socialLinks.map((social, i) => (
    <SocialLink key={`social-link-${i}`} href={social.link}>
      <SocialIcon src={social.icon} alt={social.name} />
    </SocialLink>
  ));

  // Bottom items
  const bottomItems = footer.bottom.map(({ bottom_item }, i) => (
    <BottomItem key={i}>
      {RichText.asText(bottom_item.raw)}
    </BottomItem>
  ));

  return (
    <Section>
      <Container>
        <Column center>
          <Logo src={footerLogo} alt='dw' />
        </Column>
        <Column>
          {promotionColumn}
        </Column>
        <Column>
          {newsletterColumn}
        </Column>
      </Container>
      <Bottom>
        <BottomContainerLarge>
          {bottomItems}
          <BottomItem>
            {socialMenu}
          </BottomItem>
        </BottomContainerLarge>
        <BottomContainerSmall>
          <BottomGridLeft>
            {bottomItems}
          </BottomGridLeft>
          <BottomGridRight>
            {socialMenu}
          </BottomGridRight>
        </BottomContainerSmall>
      </Bottom>
    </Section>
  );
};

export default Footer;