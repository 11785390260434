import React from 'react';
import { Input, TextArea } from './styles';

const InputField = ({ type, id, placeholder, onChange, valid, rows, required, withButton }) => {
  if (type === 'textarea') {
    return (
      <TextArea
        valid={valid}
        id={id || "input"}
        name={id || "input"}
        placeholder={placeholder || ''}
        rows={rows || "5"}
        onChange={onChange}
        required={required || false}
      />
    );
  } else {
    return (
      <Input
        valid={valid}
        id={id || "input"}
        name={id || "input"}
        type={type || "text"}
        placeholder={placeholder || ''}
        onChange={onChange}
        required={required || false}
        withButton={withButton}
      />
    );
  }
};

export default InputField;