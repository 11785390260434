import museo100Woff2 from "./MuseoSans-100.woff2";
import museo100Woff from "./MuseoSans-100.woff";
import museo300Woff2 from "./MuseoSans-300.woff2";
import museo300Woff from "./MuseoSans-300.woff";
import museo500Woff2 from "./MuseoSans-500.woff2";
import museo500Woff from "./MuseoSans-500.woff";
import museo700Woff2 from "./MuseoSans-700.woff2";
import museo700Woff from "./MuseoSans-700.woff";

export {
  museo100Woff2,
  museo100Woff,
  museo300Woff2,
  museo300Woff,
  museo500Woff2,
  museo500Woff,
  museo700Woff2,
  museo700Woff
};