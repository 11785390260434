export const linkResolver = doc => {
  // Work
  if (doc.type === 'work') {
    return `/work/${doc.uid}/`;
  }

  // All internal
  if (doc.type) {
    return `/${doc.uid}/`;
  }

  // Backup for all other types
  return '/';
};