import React from 'react';
import { navigate } from 'gatsby';
import { Button } from './styles';

const RaisedButton = ({ text, link, type, border }) => {
  // Link
  const action = () => link && navigate(link);

  return (
    <Button 
      type={type || "button"} 
      onClick={action} 
      border={border}
    >
      {text}
    </Button>
  );
};

export default RaisedButton;