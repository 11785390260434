import styled from '@emotion/styled';
import { breakpoints, dimensions } from '../../../utils/styles';

export const Section = styled.footer`
  background: ${({ theme }) => theme.dark};
  color: ${({ theme }) => theme.textLight};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${dimensions.contentWidth};
  padding: 64px 16px 40px;
  margin: 0 auto;

  @media (max-width: ${breakpoints.m}px){
    flex-direction: column;
    align-items: center;
    padding: 48px 24px 0;
  }
`;

export const Column = styled.div`
  max-width: 350px;
  padding: 0 16px;

  @media (max-width: ${breakpoints.m}px){
    max-width: 500px;
    width: 100%;
    padding: 0;
    text-align: ${({ center }) => center ? 'center' : 'left'};
    margin-bottom: 48px;
  }
`;

export const ColumnTitle = styled.h3`
  font-family: MuseoSans,sans-serif;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const Logo = styled.img`
  margin-bottom: 0;
  min-width: 80px;
  min-height: 80px;
`;

export const Promotion = styled.p`
  font-size: 18px;
  max-width: 600px;
`;

export const MailForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const MailInput = styled.div`
  display: flex;
`;

export const MailLabel = styled.label`
  font-size: 24px;
  font-family: MuseoSans, sans-serif;
  font-weight: 500;
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.l}px){
    font-size: 22px;
  }
`;

export const MailDescription = styled.p`
  font-size: 18px;
  margin-bottom: 24px;
`;

export const MailButton = styled.button`
  font-size: 16px;
  font-family: MuseoSans, sans-serif;
  font-weight: 700;
  border-radius: 0 8px 8px 0;
  color: ${({ theme }) => theme.textLight};
  background: ${({ theme }) => theme.secondary};
  padding: 8px 16px;
  outline: 0;
  border: 0;
`;

export const MailResult = styled.p`
  font-size: 14px;
  font-family: MuseoSans, sans-serif;
  margin: 16px 0 0;

  @media (max-width: ${breakpoints.l}px){
    font-size: 12px;
  }
`

export const Bottom = styled.div`
  background: ${({ theme }) => theme.black};
`;

export const BottomContainerLarge = styled.div`
  margin: 0 auto;
  line-height: 24px;
  max-width: ${dimensions.contentWidth};
  display: flex;
  justify-content: space-between;
  padding: 16px;

  @media (max-width: ${breakpoints.l}px){
    display: none;
  }
`;

export const BottomContainerSmall = styled.div`
  margin: 0 auto;
  line-height: 24px;
  max-width: ${dimensions.contentWidth};
  display: none;

  @media (max-width: ${breakpoints.l}px){
    display: flex;
    justify-content: space-between;
    padding: 24px;
  }
`;

export const BottomGridLeft = styled.div`
  margin-right: 16px;
`;

export const BottomGridRight = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-left: 16px;
`;

export const BottomItem = styled.div`
  font-size: 18px;

  @media (max-width: ${breakpoints.l}px){
    margin: 8px 0;
  }
`;

export const SocialLink = styled.a`
  vertical-align: middle;
  padding: 0 16px;

  @media (max-width: ${breakpoints.l}px){
    display: flex;
    align-items: center;
  }
`;

export const SocialIcon = styled.img`
  width: 18px;
  height: 18px;
  margin: 0;
`;
