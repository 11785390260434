import React, { useContext, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'emotion-theming';
import { GlobalStyle, theme } from '../../utils/styles';
import PropTypes from 'prop-types';

import ContextProvider from '../../provider/ContextProvider';
import ThemeContext from '../../context/ThemeContext';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, transparent }) => {
  // Context
  const { isDark, toggleTheme } = useContext(ThemeContext);

  // Scroll to top on page change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Query
  const layoutQuery = graphql`
    query SiteTitleQuery {
      prismicMenu {
        data {
          menu_items {
            menu_link {
              link_type
              lang
              uid
              type
            }
            menu_title {
              raw
            }
          }
        }
      }
      prismicFooter {
        data {
          bottom {
            bottom_item {
              raw
            }
          }
          footer {
            raw
          }
          newsletter_text {
            raw
          }
          newsletter_title {
            raw
          }
          promotion_button_link {
            link_type
            lang
            type
            uid
          }
          promotion_button_text {
            raw
          }
          promotion_text {
            raw
          }
          promotion_title {
            raw
          }
          newsletter_input_placeholder
          newsletter_button_text
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={`${layoutQuery}`}
      render={data => {
        // Data
        const { prismicMenu, prismicFooter } = data;

        // Menu
        const menuLinks = prismicMenu.data.menu_items;

        return (
          <ThemeProvider theme={isDark ? theme.dark : theme.light}>
            <GlobalStyle />
            <Header 
              siteTitle={data.site.siteMetadata.title}
              toggleTheme={toggleTheme}
              isDark={isDark}
              menuLinks={menuLinks}
              transparent={transparent}
            />
            <main>{children}</main>
            <Footer
              footer={prismicFooter.data}
            />
          </ThemeProvider> 
        );
      }}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  transparent: PropTypes.bool
};

// Wrap with context
const LayoutWrapper = ({ children, transparent }) => {
  return (
    <ContextProvider>
      <Layout transparent={transparent}>
        {children}
      </Layout>
    </ContextProvider>
  );
};

LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  transparent: PropTypes.bool
};

export default LayoutWrapper;
