import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { Link } from 'gatsby';
import { breakpoints, dimensions } from '../../../utils/styles';

const slidein = keyframes`
  from {
    opacity: 0;
    transform: scale(0.6) translateY(-8px);
  }
  to {
    opacity: 1;
  }
`;

export const Gap = styled.div`
  height: 64px;

  @media (max-width: ${breakpoints.l}px){
    height: 54px;
  }
`;

export const Section = styled.header`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1200;
  width: 100%;
  transition: transform .45s cubic-bezier(.5,.08,0,.99), background-color .1s ease-in-out;
  background-color: ${({ theme, open, white }) => open ? 'black' : white ? 'transparent' : theme.white};
  height: ${({ open }) => open ? '100%;' : 'initial'};
  transform: ${({ hide }) => hide ? 'translate3d(0,-100%,0)' : 'none'};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${dimensions.contentWidth};
  margin: 0 auto;
  padding: 16px;

  @media (max-width: ${breakpoints.l}px){
    padding: 0 8px 0 24px;
    height: 56px;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  height: 32px;
  margin: 4px 0 0;

  @media (max-width: ${breakpoints.l}px){
    height: 28px;
  }
`;

export const Menu = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.l}px){
    display: none;
  }
`;

export const MobileMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 56px);
`;

export const MenuItem = styled.div`
  font-family: MuseoSans, sans-serif;
  font-weight: 500;
  font-size: 24px;
  padding-left: 32px;
  text-decoration: none;

  @media (max-width: ${breakpoints.l}px){
    font-size: 32px;
    padding: 12px 24px;
    animation-name: ${slidein};
    animation-duration: 350ms;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-delay: ${({ index }) => `calc(${index} * 100ms)`};
  }
`;

export const ExternalLink = styled.a`
  color: ${({ theme, white }) => white ? theme.textLight : theme.textDark};
  text-decoration: none;
`;

export const InternalLink = styled(Link)`
  color: ${({ theme, white }) => white ? theme.textLight : theme.textDark};
  text-decoration: none;
`;

export const ToggleButton = styled.button`
  outline: none;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
`;

export const ToggleIcon = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ theme, isDark }) => isDark ? theme.textDark : 'transparent'};
  transform: ${({ isDark }) => isDark ? 'scale(0.55)' : 'scale(1)'};
  transition: all 0.45s ease;
  overflow: ${({ isDark }) => isDark ? 'visible' : 'hidden'};
  box-shadow: ${({ theme, isDark, white }) => isDark ? 'none' : white ? `inset 8px -8px 0px 0px ${theme.textLight}` : `inset 8px -8px 0px 0px ${theme.textDark}`};

  &:before {
    content: '';
    position: absolute;
    right: -9px;
    top: -9px;
    height: 24px;
    width: 24px;
    border: ${({ theme, isDark }) => isDark ? `2px solid ${theme.textDark}` : 'none'};
    border-radius: 50%;
    transform: ${({ isDark }) => isDark ? 'translate(14px, -14px)' : 'translate(0, 0)'};
    opacity: ${({ isDark }) => isDark ? '0' : '1'};
    transition: transform 0.45s ease;
  }

  &:after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: -4px 0 0 -4px;
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: ${({ theme }) => `0 -23px 0 ${theme.textDark}, 0 23px 0 ${theme.textDark}, 23px 0 0 ${theme.textDark}, -23px 0 0 ${theme.textDark}, 15px 15px 0 ${theme.textDark}, -15px 15px 0 ${theme.textDark}, 15px -15px 0 ${theme.textDark}, -15px -15px 0 ${theme.textDark}`};
    transform: ${({ isDark }) => isDark ? 'scale(1)' : 'scale(0)'};
    transition: all 0.35s ease;
  }
`;

export const MenuButtons = styled.div`
  display: flex;
`;

export const MenuButton = styled.div`
  display: flex;
`;

export const Hamburger = styled.button`
  outline: none;
  border: none;
  background: none;
  padding: 0 16px;

  @media (min-width: ${breakpoints.l}px){
    display: none;
  }
`;

export const MenuIcon = styled.div`
  width: 24px;
  height: 18px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  margin: 0;

  span {
    display: block;
    position: absolute;
    overflow: hidden;
    height: 2px;
    width: 100%;
    background: ${({ theme, open, white }) => white || open ? theme.textLight : theme.textDark};
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  
    :nth-of-type(1) {
      transform-origin: left center;
      top: 0px;
      left: ${({ open }) => open ? '3px' : '0'};
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0deg)'};
    }
  
    :nth-of-type(2) {
      top: 8px;
      transform-origin: left center;
      width: ${({ open }) => open ? '0%' : '100%'};
      opacity: ${({ open }) => open ? '0' : '1'};
    }
  
    :nth-of-type(3) {
      top: ${({ open }) => open ? '17px' : '16px'};
      transform-origin: left center;
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0deg)'};
      left: ${({ open }) => open ? '3px' : '0'};
    }
  }
`;
