import { useState, useEffect, useCallback } from 'react';

// Hook
const useWidth = () => {

  // Check if client
  const isClient = typeof window !== 'undefined';

  // Get width
  const getWidth = useCallback(() => isClient ? window.innerWidth : undefined, [isClient]);

  // State
  const [width, setWidth] = useState(getWidth);

  useEffect(() => {
    if (!isClient) return false;
    const handleResize = () => setWidth(getWidth());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient, getWidth]);

  return width;
}

export default useWidth;