import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { StaticQuery, graphql } from 'gatsby';

import * as fonts from '../fonts';
import defaultImage from '../assets/ogdefault.png';

const SEO = ({ title, description, image, type, noTemplate }) => {
  const { pathname } = useLocation();
  const siteQuery = graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: siteUrl
          twitterUsername
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={`${siteQuery}`}
      render={({ site }) => {
        
        const {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          twitterUsername,
        } = site.siteMetadata;

        const seo = {
          title: title,
          description: description || defaultDescription,
          image: image || {
            url: `${siteUrl}${defaultImage}`,
            alt: 'DW',
            dimensions: {
              width: 1200,
              height: 630
            }
          },
          url: `${siteUrl}${pathname}`
        };

        return (
          <Helmet title={seo.title} titleTemplate={noTemplate ? null : titleTemplate} defaultTitle={defaultTitle}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image.url} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(type ? true : null) && <meta property="og:type" content={type} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image.url} />}
            {seo.image && <meta property="og:image:width" content={seo.image.dimensions.width} />}
            {seo.image && <meta property="og:image:height" content={seo.image.dimensions.height} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && <meta name="twitter:site" content={twitterUsername} />}
            {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.image.url} />}
            {seo.image && <meta name="twitter:image:alt" content={seo.image.alt} />}
            <html lang="en" />
            <link rel="preload" as="font" href={fonts.museo700Woff2} type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" as="font" href={fonts.museo500Woff2} type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" as="font" href={fonts.museo300Woff2} type="font/woff2" crossOrigin="anonymous" />
            <link rel="preload" as="font" href={fonts.museo100Woff2} type="font/woff2" crossOrigin="anonymous" />
          </Helmet>
        );
      }}
    />
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  type: PropTypes.string,
  noTemplate: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  type: null,
  noTemplate: false
};

export default SEO;
