import styled from '@emotion/styled';

export const Input = styled.input`
  width: 100%;
  height: 56px;
  padding: 0 16px;
  border: ${({ theme, valid, withButton }) => valid ? `4px solid ${theme.secondary}` : withButton ? 'none' : `1px solid ${theme.lighter}` };
  border-radius: ${({ withButton }) => withButton ? '8px 0 0 8px' : '8px'}; 
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.textDark};
  outline: none;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 16px;
  border: ${({ theme, valid }) => valid ? `4px solid ${theme.secondary}` : `1px solid ${theme.lighter}` };
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.textDark};
  border-radius: 8px;
  outline: none;
`;